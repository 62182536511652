import React, { useState, useEffect } from "react";
import {
    Grid,
    Paper,
    Typography,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TablePagination,
    TextField,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    useMediaQuery,
    Box,
} from "@mui/material";
import { ImportExport } from '@mui/icons-material';

import ReportDepositDesktop from './ReportDepositDesktop';
import ReportDepositMobile from './ReportDepositMobile';

//exportTransaction
import * as XLSX from "xlsx";

//calendar
import dayjs from "dayjs";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

//theme
import { SearchButton } from '../../../Theme.jsx'

//icons
import { SearchTextIcon } from '../../../untils/icons.js'

//services
import { POST, GET, TRANSACTIONSDEPOSIT, MERCHANT } from "../../../service/service.js"
import { thousandsSeparator } from "../../../untils/shortcut.js";
import moment from "moment-timezone";


function ReportDeposit() {
    const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    const isMobile = useMediaQuery('(max-width:599px)');

    const [text, setText] = useState('')
    const [exportTransaction, setExportTransaction] = useState([]);
    const [transactions, setTransactions] = useState([]);
    const [broker, setBroker] = useState("All");
    const [brokerList, setBrokerList] = useState([]);
    const [status, setStatus] = useState("All")
    const [startDate, setStartDate] = useState(moment().startOf("month").tz(userTimeZone));
    const [endDate, setEndDate] = useState(moment().endOf('day').tz(userTimeZone));
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);

    useEffect(() => {
        getTransactionMerchant({ merchant_name: broker, startDate, endDate, search: text, })
        getBroker();
    }, []);

    const getTransactionMerchant = async ({ merchant_name: broker, startDate, endDate, search: text, }) => {
        try {
            const res = await POST(TRANSACTIONSDEPOSIT, {
                search: text,
                merchant_name: broker,
                status: status,
                startDate: startDate,
                endDate: endDate
            })
            if (res.success) {
                const sortedData = res.result.sort((a, b) =>
                    (b.id) - (a.id)
                );
                setTransactions(sortedData)
                setExportTransaction(res.result)
            } else {
                alert(res.message)
            }
        } catch (error) {
            alert("error", error)
        }
    }

    const getBroker = async () => {
        try {
            const res = await GET(MERCHANT);
            if (res.success) {
                setBrokerList(res.result);
            } else {
                alert(res.message);
            }
        } catch (error) {
            alert("Error fetching brokers");
        }
    };

    const handleSearch = () => {
        getTransactionMerchant({ merchant_name: broker, startDate, endDate, search: text, })
        setPage(0);
    };

    const getStatusColor = (status) => {
        switch (status) {
            case 'pending':
                return '#D4B700';
            case 'successful':
                return '#13B526';
            case 'failed':
                return '#FF0000';
            default:
                return 'black';
        }
    };

    const handleChangePage = (e, newPage) => {
        setPage(newPage);
    };


    const handleChangeRowsPerPage = (e) => {
        setRowsPerPage(+e.target.value);
        setPage(0);
    };

    const handleChangeStartDate = (startValue) => {
        if (startValue) {
            if (startValue.isAfter(endDate)) {
                setEndDate(startValue.add(1, "second"));
            } else if (startValue.isSame(endDate)) {
                setEndDate(startValue.add(1, "second"));
            }
            setStartDate(startValue);
        }
    };

    const handleChangeEndDate = (endValue) => {
        if (endValue) {
            if (endValue.isBefore(startDate)) {
                setStartDate(endValue.subtract(1, "second"));
            } else if (endValue.isSame(startDate)) {
                setStartDate(endValue.subtract(1, "second"));
            }
            setEndDate(endValue);
        }
    };

    const exTransaction = () => {
        if (!exportTransaction || exportTransaction.length === 0) {
            alert("No data available to export");
            return;
        }

        let data = exportTransaction.map(e => {
            return {
                "TransactionsID": e.id,
                "MerchantName": e.merchant.name,
                "Amount": thousandsSeparator(e.amount),
                "Balance Before": thousandsSeparator(e.balanceBefore),
                "Balance After": thousandsSeparator(e.balanceAfter),
                "Amount With Fee": thousandsSeparator(e.amountWithFee),
                "ClientTransactionID": e.clientTransactionId,
                "Status": e.status,
                "Date Created": moment(e.dateCreated).tz(userTimeZone).format("DD/MMM/YYYY HH:mm:ss"),
                "Date Updated": moment(e.dateUpdated).tz(userTimeZone).format("DD/MMM/YYYY HH:mm:ss")
            };
        });

        const worksheet = XLSX.utils.json_to_sheet(data);

        const range = XLSX.utils.decode_range(worksheet["!ref"]);
        for (let col = range.s.c; col <= range.e.c; col++) {
            const header = XLSX.utils.encode_col(col) + "1";
            const headerValue = worksheet[header]?.v;

            if (
                ["amount", "balanceBefore", "balanceAfter", "amountWithFee"].includes(
                    headerValue
                )
            ) {
                for (let row = 2; row <= range.e.r + 1; row++) {
                    const cell = XLSX.utils.encode_cell({ r: row - 1, c: col });
                    if (worksheet[cell]) {
                        worksheet[cell].t = "n";
                        worksheet[cell].z = "#,##0.00";
                    }
                }
            }
        }

        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "WithDraw");

        const fileName = `${startDate.format("DD/MMM/YYYY")}_${endDate.format("DD/MMM/YYYY")}_Deposit_transactions_merchant_${broker}_status_${status}.xlsx`

        XLSX.writeFile(workbook, fileName);
    };

    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Grid>
                <Box display="flex" justifyContent="center">
                    <Paper sx={{ width: '90%', my: 1 }} elevation={0}>
                        <Typography variant="caption" color="#2996D1" fontSize={18} display="flex" fontWeight="bold" alignItesm="center" sx={{ pt: 2, px: 1.5 }}>
                            ค้นหา
                        </Typography>
                        <Grid item xs={12} sx={{ p: 1.5 }}>
                            <Grid container spacing={2} marginBottom={2} alignItems="center">
                                <Grid item xs={12} sm={4}>
                                    <FormControl fullWidth>
                                        <TextField
                                            value={text}
                                            onChange={(e) => setText(e.target.value)}
                                            variant="outlined"
                                            placeholder='ค้นหา'
                                            required
                                            fullWidth
                                        >
                                        </TextField>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} sm={2}>
                                    <FormControl fullWidth>
                                        <InputLabel>ร้านค้า</InputLabel>
                                        <Select
                                            value={broker}
                                            onChange={(e) => setBroker(e.target.value)}
                                            label='Broker'
                                        >
                                            <MenuItem value={"All"}>{"All"}</MenuItem>
                                            {brokerList.map((item) => (
                                                <MenuItem key={item.name} value={item.name}>
                                                    {item.name}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} sm={2}>
                                    <FormControl fullWidth>
                                        <InputLabel>Status</InputLabel>
                                        <Select
                                            value={status}
                                            onChange={(e) => setStatus(e.target.value)}
                                            label='Status'
                                        >
                                            <MenuItem value={'All'}>ทั้งหมด</MenuItem>
                                            <MenuItem value={'pending'}>Pending</MenuItem>
                                            <MenuItem value={'successful'}>Successful</MenuItem>
                                            <MenuItem value={'failed'}>Failed</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} sm={2}>
                                    <DesktopDatePicker
                                        label="วันที่เริ่มต้น"
                                        inputFormat="DD/MMM/YYYY HH:mm:ss"
                                        value={startDate}
                                        onChange={handleChangeStartDate}
                                        maxDate={dayjs()}
                                        renderInput={(params) => <TextField {...params} fullWidth />}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={2}>
                                    <DesktopDatePicker
                                        label="วันที่สิ้นสุด"
                                        inputFormat="DD/MMM/YYYY HH:mm:ss"
                                        value={endDate}
                                        onChange={handleChangeEndDate}
                                        minDate={startDate}
                                        maxDate={dayjs()}
                                        renderInput={(params) => <TextField {...params} fullWidth />}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={2} md={2} sx={{ mt: 1 }}>
                                    <SearchButton width="auto" variant="outlined" onClick={handleSearch} startIcon={<SearchTextIcon />}>ค้นหา</SearchButton>
                                </Grid>
                                <Grid item xs={12} sm={2} md={2} sx={{ mt: 1 }}>
                                    <SearchButton width="auto" variant="outlined" onClick={exTransaction} startIcon={<ImportExport />}>Export</SearchButton>
                                </Grid>
                            </Grid>
                            {isMobile ? (
                                <ReportDepositMobile
                                    transactions={transactions}
                                    page={page}
                                    rowsPerPage={rowsPerPage}
                                    handleChangePage={handleChangePage}
                                    handleChangeRowsPerPage={handleChangeRowsPerPage}
                                    getStatusColor={getStatusColor}
                                />
                            ) : (
                                <ReportDepositDesktop
                                    transactions={transactions}
                                    page={page}
                                    rowsPerPage={rowsPerPage}
                                    handleChangePage={handleChangePage}
                                    handleChangeRowsPerPage={handleChangeRowsPerPage}
                                    getStatusColor={getStatusColor}
                                />
                            )}
                        </Grid>
                    </Paper>
                </Box>
            </Grid >
        </LocalizationProvider >
    );
};



export default ReportDeposit;