import { FormControl, Grid, InputAdornment, InputLabel, MenuItem, OutlinedInput, Select } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { GET, MERCHANT, POST, WITHDRAWBROKER } from '../../service/service';
import { SearchButton } from '../../Theme';

function WithdrawsBroker() {
    const [merchant, setMerchant] = useState("All");
    const [merchantList, setMerchantList] = useState([]);
    const [withdrawAmount, setWithdrawAmount] = useState('');
    const [fee, setFee] = useState('');

    useEffect(() => {
        getMerchant();
    }, []);

    const withdrawBroker = async (broker, amount, fee) => {
        try {
            const res = await POST(WITHDRAWBROKER, {
                broker: broker,
                amount: amount,
                fee: fee
            });
            if (res.success) {
                setWithdrawAmount('');
                setFee('');
                alert(`Withdrawal successful: ฿${amount}`);
            } else {
                alert(res.message);
            }
        } catch (error) {
            console.error('Withdrawal error:', error);
            alert('Withdrawal failed. Please try again.');
        }
    };

    const getMerchant = async () => {
        try {
            const res = await GET(MERCHANT);
            if (res.success) {
                setMerchantList(res.result);
            } else {
                alert(res.message);
            }
        } catch (error) {
            console.error('Error fetching merchants:', error);
            alert("Failed to fetch merchants.");
        }
    };

    const handleWithdraw = async () => {
        if (!withdrawAmount) {
            alert('Please enter an amount');
            return;
        }
        await withdrawBroker(merchant, withdrawAmount, fee);
    };

    return (
        <Grid container alignItems="center" justifyContent="center" spacing={2}>
            <Grid item xs={12} sm={3}>
                <FormControl fullWidth>
                    <InputLabel>ร้านค้า</InputLabel>
                    <Select
                        value={merchant}
                        onChange={(e) => setMerchant(e.target.value)}
                        label="ร้านค้า"
                    >
                        <MenuItem value="All">All</MenuItem>
                        {merchantList.map((item) => (
                            <MenuItem key={item.name} value={item.name}>
                                {item.name}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </Grid>
            <Grid item xs={12} sm={3}>
                <FormControl fullWidth>
                    <InputLabel htmlFor="outlined-adornment-amount">Amount</InputLabel>
                    <OutlinedInput
                        id="outlined-adornment-amount"
                        startAdornment={<InputAdornment position="start">฿</InputAdornment>}
                        label="Amount"
                        value={withdrawAmount}
                        onChange={(e) => setWithdrawAmount(e.target.value)}
                        onKeyDown={(e) => {
                            if (e.key === 'Enter') {
                                handleWithdraw();
                            }
                        }}
                    />
                </FormControl>
            </Grid>
            <Grid item xs={12} sm={3}>
                <FormControl fullWidth>
                    <InputLabel htmlFor="outlined-adornment-fee">Fee</InputLabel>
                    <OutlinedInput
                        id="outlined-adornment-fee"
                        label="Fee"
                        value={fee}
                        onChange={(e) => setFee(e.target.value)}
                    />
                </FormControl>
            </Grid>
            <Grid item xs={12} sm={3}>
                <SearchButton
                    fullWidth
                    variant="outlined"
                    onClick={handleWithdraw}
                >
                    Withdraw
                </SearchButton>
            </Grid>
        </Grid>
    );
}

export default WithdrawsBroker;