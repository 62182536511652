import React, { useEffect, useState } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Typography } from '@mui/material';
import { GET, WITHDRAWLISTBROKER } from '../../service/service';
import { thousandsSeparator } from '../../untils/shortcut';

function TableData() {
    const [withdrawlist, setWithdrawlist] = useState([]);

    const fetchWithdrawListBroker = async () => {
        try {
            const res = await GET(WITHDRAWLISTBROKER);
            if (res.success) {
                setWithdrawlist(res.result);
            } else {
                alert(res.message);
            }
        } catch (error) {
            alert("Error fetching withdrawals: " + error);
        }
    };

    useEffect(() => {
        fetchWithdrawListBroker();
    }, []);

    return (
        <TableContainer component={Paper}>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>No.</TableCell>
                        <TableCell>Date</TableCell>
                        <TableCell>Amount</TableCell>
                        <TableCell>Fee</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {withdrawlist.length > 0 ? (
                        withdrawlist.map((withdrawal, index) => (
                            <TableRow key={withdrawal.id}>
                                <TableCell>{index + 1}</TableCell>
                                <TableCell>{withdrawal.date}</TableCell>
                                <TableCell>{thousandsSeparator(withdrawal.amount)}</TableCell>
                                <TableCell>{thousandsSeparator(withdrawal.fee)}</TableCell>
                            </TableRow>
                        ))
                    ) : (
                        <TableRow>
                            <TableCell colSpan={4} align="center">
                                <Typography variant="body2" color="textSecondary">
                                    No records to display
                                </Typography>
                            </TableCell>
                        </TableRow>
                    )}
                </TableBody>
            </Table>
        </TableContainer>
    );
}

export default TableData;